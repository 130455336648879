
import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start 
    activeTab:number | string,
    selectedProfessional: string,
    activeContractData: any;
    activeContractErr: string;
    clientDetailsData:  any
    startDate:any,
    dateError: string,
    endDate:any,
    authToken:string
    showDatePicker: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class FreelancerAllContractController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    activeContractId: string = ""
    clientListId: string = ""
// Customizable Area End
constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
        activeTab:0,
        activeContractData: [],
        clientDetailsData: [],
        activeContractErr: '',
        selectedProfessional: 'All Clients',
        startDate: new Date().toISOString().split('T')[0],
        dateError: '',
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) // Last day of the current month
        .toISOString()
        .split('T')[0],
        authToken:'',
        showDatePicker:false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
}

async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
        authToken: await storage.get('authToken'),
    });
    this.getActiveContract()
    this.getClientList()
    // Customizable Area End

}

// Customizable Area Start
async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      
      // return;
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.handleActiveContractRes(webResponseJson,webApiRequestCallId)
     this.handleClientListRes(webResponseJson,webApiRequestCallId)
    }
    // Customizable Area End
  }
// Customizable Area End

// Customizable Area Start


handleStartDateChange = (event: { target: { value: any; }; }) => {
    const startDate = event.target.value;
    this.setState(
        { startDate },
        () => {
            this.validateDates();
            this.getActiveContract();
        }
    );
};

  handleActiveContractRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
    if (webResponseJson) {
    
        if (webApiRequestCallId === this.activeContractId) {
            if(Array.isArray(webResponseJson?.data)){
            this.setState({ activeContractData: webResponseJson?.data,activeContractErr:"" })
          }
          if(webResponseJson?.error){
            this.setState({ activeContractErr: webResponseJson?.error })
          }
        }
      }
  }

  handleOpen = () => {
    this.setState({ showDatePicker: true });
  };

  handleClose = () => {
    this.setState({ showDatePicker: false });
  };


  handleEndDateChange = (event: { target: { value: any; }; }) => {
    const endDate = event.target.value;
    this.setState(
        { endDate },
        () => {
            this.validateDates();
            this.getActiveContract();
        }
    );
};


  toggleDatePicker = () => {
    this.setState((prevState) => ({ showDatePicker: !prevState.showDatePicker }));
  };

  handleDropdownChange = (event: any) => {
    this.setState(
        {
            selectedProfessional: event.target.value,
        },
        () => {
            this.getActiveContract();
            this.getClientList()
        }
    );
};
handleDateChange = (update: [any, any]) => {
  const [startDate, endDate] = update;
  this.setState({ startDate, endDate });
};

goToActiveContract = () => {
    this.props.navigation.navigate("FreelancerActiveContract");
}

  getActiveContract = async () => {
    const header = {
        "Content-Type": webConfigJSON.validationApiContentType,
        token: this.state.authToken,
    };
    let endpoint =
        this.state.activeTab === 0
            ? `bx_block_contract/contracts/active_contracts`
            : `bx_block_contract/contracts/archived_contracts`;

    const params: string[] = [];
    if (this.state.selectedProfessional && this.state.selectedProfessional !== "All Clients") {
        params.push(`account_id=${this.state.selectedProfessional}`);
    }
    if (this.state.startDate) {
        params.push(`start_date=${this.state.startDate}`);
    }
    if (this.state.endDate) {
        params.push(`end_date=${this.state.endDate}`);
    }
    const queryString = params.length > 0 ? `?${params.join("&")}` : "";
    endpoint += queryString;


    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activeContractId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

};


  validateDates = () => {
    const { startDate, endDate } = this.state;
    if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
      this.setState({
        dateError: "End date cannot be earlier than start date.",
      });
    } else {
      this.setState({ dateError: "" });
    }
  };



  handleClientListRes = (webResponseJson: { data: any; } | undefined,webApiRequestCallId: string | undefined) =>{
    if (webResponseJson) {
    
        if (webApiRequestCallId === this.clientListId) {
          if(webResponseJson?.data){
            this.setState({ clientDetailsData: webResponseJson?.data })
          }
        }
      }
  }

  
  getClientList = async () => {
    const paramId = this.props.navigation.getParam("id")

    let endpoint =
    this.state.activeTab === 0
        ? `bx_block_contract/contracts/get_my_contract_clients`
        : `bx_block_contract/contracts/get_my_archived_contract_clients`;

    const header = {
        "Content-Type": webConfigJSON.validationApiContentType,
        token: this.state.authToken
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.clientListId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};

handleTabChange = (event: any, newValue: number) => {
       this.setState({ activeTab: newValue }, () => {
        this.getActiveContract();
        this.getClientList()
    });
};

// Customizable Area End
}