import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button,
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import FooterWeb from "./Components/Footer.web";
import FreelancerNavBar from "./FreelancerNavBar.web";


import FreelancerAllDisputesController, {
    Props,
    webConfigJSON,
} from "./FreelancerAllDisputesController";

// Customizable Area End

export default class FreelancerAllDisputes extends FreelancerAllDisputesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    renderSelect = () => {
        return (
            <Box style={{ display: "flex" }} >
                <CurUpperText>
                    {webConfigJSON.myJobs}
                </CurUpperText>
                <ArrowIcon>
                    {">"}
                </ArrowIcon>
                <CurUpperText>
                    {webConfigJSON.allcontract}
                </CurUpperText>
                <ArrowIcon>
                    {">"}
                </ArrowIcon>
                <FontActiveText>
                    {webConfigJSON.ArchivedContract}
                </FontActiveText>
            </Box>
        )
    };

    renderContract = () => {
        return (
            <>
                <Box>
                    <HeaderTxt>
                        All Disputes
                    </HeaderTxt>
                    <TitleTxt>
                        Review all disputes related to the project. Track the progress, decisions, and outcomes in one centralized location.
                    </TitleTxt>
                </Box>
            </>
        );
    };

    renderProject = () => {
        return (
            <ProjectOuterMainBox>
                <Box className="projectDes">
                    <Typography style={{
                        color: '#303030',
                        fontSize: '24px',
                        fontFamily: 'Rubik',
                        fontWeight: 700,
                        marginBottom: '32px'
                    }}></Typography>
                    <Box >
                        <Box className="projectContent">
                            <Typography style={webStyle.clientLeftAtrribute} >Project :</Typography>
                            <Typography style={{ ...webStyle.clientRightAtrribute, textTransform: 'capitalize' }}>
                            {this.state.allDetailsDisputesData?.attributes?.post_attributes?.data?.attributes?.name}
                            </Typography>
                        </Box>
                        <Box className="projectContent">
                            <Typography style={webStyle.clientLeftAtrribute} >IP Client :

                            </Typography>
                            <Typography style={{ ...webStyle.clientRightAtrribute, textTransform: 'capitalize' }}>
                            {this.state.allDetailsDisputesData?.attributes?.client_details?.client_name}
                            </Typography>
                        </Box>
                        <Box className="projectContent">
                            <Typography style={webStyle.clientLeftAtrribute} >IP Professional :</Typography>
                            <Typography style={{ ...webStyle.clientRightAtrribute, textTransform: 'capitalize' }}>
                            {this.state.allDetailsDisputesData?.attributes?.freelancer_details?.freelancer_name}</Typography>
                        </Box>
                    </Box>
                </Box>
                {this.renderMilestone()}
            </ProjectOuterMainBox>
        );
    }

    renderButtons = () => {
    const paramsId = this.props.navigation.getParam("id")

        return (
            <CustomButton >
                <Box style={{ marginTop: '48px' }}>
                    <Button
                        variant="contained"
                        className="alldisputeBtn"
                        color="primary"
                        data-testid="dailog-withdraw-proposal-btn"
                        style={{
                            background: 'white',
                            color: '#303f9f',
                            padding: '15px 40px',
                            fontSize: '18px',
                            fontFamily: 'Rubik',
                            border: '1px solid #303f9f',
                            marginLeft: '60px',
                            borderRadius: '16px'
                        }}
                        onClick={() => this.props.navigation.navigate('FreelancerRaiseDisputes', {id: paramsId })}
                    >
                        BACK
                    </Button>

                </Box>
            </CustomButton>
        )
    }


    renderMilestone = () => {
        const rows = [
            {
                id: 1,
                details: 'Amount not paid for 3rd milestone',
                milestone: 'Milestone 3',
                amount: '$20',
                status: 'Received refund',
                comment: 'Lorem ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best.',
            },
            {
                id: 2,
                details: 'Amount not paid for 5th milestone',
                milestone: 'Milestone 5',
                amount: '$40',
                status: 'Gave refund',
                comment: 'Lorem ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best.',
            },
            {
                id: 3,
                details: 'Amount not paid for 4th milestone',
                milestone: 'Milestone 4',
                amount: '$20',
                status: 'Received refund',
                comment: 'Received refund',
            },
            {
                id: 4,
                details: 'Amount not paid for 1st milestone',
                milestone: 'Milestone 1',
                amount: '$40',
                status: 'Received refund',
                comment: 'Received refund',
            },
        ];
            
        const allDisutesList = this.state.allDisputesData?.refunds?.data
        return (
            <MilestoneBox>
                 <Box>
      <TableContainer component={Paper}>
        <Table aria-label="responsive table">
          <TableHead style={{borderTop: '1px solid #ECECEC'}}>
            <TableRow>
              <TableCell style={{padding: '27px'}} align="center">
                <Typography
                  style={{
                    color: '#0A0A0A',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                </Typography>
              </TableCell>
              <TableCell style={{padding: '27px',width: '20%'}}>
                <Typography
                  style={{
                    color: '#0A0A0A',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    fontSize: '16px'
                  }}
                >
                  Details
                </Typography>
              </TableCell>
              <TableCell style={{padding: '27px'}}>
                <Typography
                  style={{
                    color: '#0A0A0A',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                  Milestone
                </Typography>
              </TableCell>
              <TableCell style={{padding: '27px'}}>
                <Typography
                  style={{
                    color: '#0A0A0A',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                  Amount
                </Typography>
              </TableCell>
              <TableCell style={{padding: '27px'}}>
                <Typography
                  style={{
                    color: '#0A0A0A',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell style={{padding: '27px'}}>
                <Typography
                  style={{
                    color: '#0A0A0A',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                  Comment
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allDisutesList?.length > 0 && allDisutesList?.map((item: any, index: any) => {
                return(
                    <>
                       <TableRow key={index}>
                <TableCell align="center">
                  <Typography>{index + 1}</Typography>
                </TableCell>
                <TableCell style={{padding: '27px'}}>
                  <Typography
                    style={{
                        ...webStyle.inputBox,
                      color: '#0A0A0A',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                    }}
                  >
                    {item?.attributes?.details}
                  </Typography>
                </TableCell>
                <TableCell style={{padding: '27px'}}>
                  <Typography
                    style={{
                        ...webStyle.inputBox,
                      color: '#0A0A0A',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                    }}
                  >
                    {item?.attributes?.milestone_id}
                  </Typography>
                </TableCell>
                <TableCell style={{padding: '27px'}}>
                  <Typography
                    style={{
                        ...webStyle.inputBox,
                      color: '#0A0A0A',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                    }}
                  >
                    {item?.attributes?.amount}
                  </Typography>
                </TableCell>
                <TableCell style={{padding: '27px'}}>
                  <Typography
                    style={{
                        ...webStyle.inputBox,
                      color: '#0A0A0A',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                    }}
                  >
                    {item?.attributes?.status}
                  </Typography>
                </TableCell>
                <TableCell style={{width: '30%',padding: '27px'}}>
                  <Typography
                    style={{
                        ...webStyle.inputBox,
                      color: '#0A0A0A',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                    }}
                  >
                    {item.attributes.comment}
                  </Typography>
                </TableCell>
              </TableRow>
                    </>
                )
            }
        )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
            </MilestoneBox>
        );
    };


    render() {
        //Customizable Area Start
       console.log(this.state.allDetailsDisputesData, 'allDetailsDisputesData')
        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation} />
                <BoxMain>
                    {this.renderContract()}
                    {this.renderProject()}
                    {this.renderButtons()}
                </BoxMain>
                <FooterWeb />
            </>
        );
        //Customizable Area End
    }

}

// Customizable Area Start
const webStyle = {
    clientLeftAtrribute: {
        color: '#3F3F3F',
        fontSize: '18px',
        fontWeight: 700,
        width: '30%',
        fontFamily: 'Rubik',
        marginBottom: '6px',
        textWrap: 'nowrap'
    },
    inputFields: {
        borderRadius: '12px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px !important'
        },
        '& .MuiOutlinedInput-input': {
            borderRadius: '12px !important'
        },
    },
    clientRightAtrribute : {
        marginBottom: '6px',
        fontSize: '18px',
        fontFamily: 'Rubik',
        width: '70%',
        color: '#545454',
    } ,
    inputBox: {
        width: '60%'
    },
};

const BoxMain =styled(Box) ({
    margin: "60px"
})

const ArrowIcon=styled(Typography)({
    margin: "0px 8px",
    fontWeight: "bold",
})

const FontActiveText = styled(Typography)({
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "17px",
    textAlign: "left",
    letterSpacing: "0px",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer",
})

const CurUpperText=styled(Typography)({
    cursor: "pointer",
    fontSize: "17px",
    fontWeight: 500,
    textAlign: "left",
    lineHeight: "24px",
    fontFamily: "Inter",
    letterSpacing: "0px",
})

const CustomButton=styled(Box)({
    justifyContent: 'space-between',
    display: 'flex',
    "@media(max-width:600px)":{
        display: 'block'
    },
    '& .endisputeBtn':{
        border: '1px solid #D60000',
        padding: '10px 45px',
        color: 'white',
        fontSize: '18px',
        fontFamily: 'Rubik',
        borderRadius: '12px',
        backgroundColor: '#D60000',
        "@media(max-width:600px)":{
            display: 'flex',
            margin: 'auto',
        }
    },
    '& .raisedisputeBtn':{
        border: '1px solid #364BA0',
        color: 'white',
        fontSize: '18px',
        fontFamily: 'Rubik',
        marginRight: '20px',
        padding: '10px 45px',
        borderRadius: '12px',
        backgroundColor: '#364BA0',
        "@media(max-width:600px)":{
            marginBottom: '40px',
            display: 'flex',
            margin: 'auto',
        }}
})

const HeaderTxt=styled(Typography)({
    fontWeight: "bold",
    fontFamily: "Rubik",
    fontSize: "36px",
    color: "3F3F3F",
})

const ProjectOuterMainBox=styled(Box)({
    gap: "30px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    display: "flex",
    '& .projectDes':{
        width: '50%',
        "@media(max-width:600px)":{
            width: '100%',
        },
        '& .projectContent':{
            gap: '100px',
            display: 'flex',
            "@media(max-width:600px)":{
                display: 'block',
            }}}
})

const TitleTxt=styled(Typography)({
    fontSize: "14px",
    color: "#595959",
    fontStyle: "normal",
    display: "flex",
    fontWeight: 400,
    margin: "20px 0px",
    lineHeight: "16px",
    width: "91%",
    fontFamily: "Rubik, sans-serif",
});

const MilestoneBox = styled(Box)({
    width: '100%',
    '& .milestoneBox' : {
        gap: '10px',
        "@media(max-width:600px)": {
            display: 'block'
        },
    },
    '& .inputBoxField' : {
        marginBottom: '20px',
        width: '60%',
        "@media(max-width:600px)": {
            width: '100%'
        },
    },
    '& .inputBoxDes' : {
        width: '80%',
        marginBottom: '20px',
        "@media(max-width:600px)" : {
            width: '100%'
        },
    },
});


// Customizable Area End