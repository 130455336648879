import React from "react";
// Customizable Area Start

import OTPVerificationController, {
    Props
  } from "./OTPVerificationController";
import { Grid, Typography, Box, FormControl, styled, Dialog, IconButton, DialogContent } from "@material-ui/core";
import { Horizontalinventohub } from "../../landingpage/src/assets";
import ReactCodeInput from "react-code-input";
import CloseIcon from '@material-ui/icons/Close';
import { image_Success, login } from "./assets";
const CustomDialog = styled(Dialog)({
    '& .MuiPaper-rounded':{
      borderRadius: "18px",
      width:"480px"
    }
  })
  const MainContainer = styled(Box)({
    paddingInline: 20,
    '@media (max-width: 959px)': {
      paddingInline: 15,
      '& .dots-img': {
        display: 'none',
      },
    },
    '@media (max-width: 600px)': {
      paddingInline: 10,
    },
    '@media (max-width: 800px)': {
    '& .password-options': { 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start !important',
      gap: '8px', 
    }
  },
  });
  
  const props = {
    inputStyle: {
      fontFamily: 'monospace',
      height:"64px",
      width: '64px',
      margin: '4px',
      MozAppearance: 'textfield' as "textfield",
      border: '1px solid gray',
      fontSize: '30px',
      backgroundColor: 'white',
      color: 'gray',
      textAlign: "center" as "center",
      borderRadius: '10px',
    },
    inputStyleInvalid: {
      borderRadius: '3px',
      MozAppearance: 'textfield' as "textfield",
      width: '40px',
      height: '26px',
      fontFamily: 'Rubik',
      fontSize: '14px',
      paddingLeft: '7px',
      border: '1px solid red',
      backgroundColor: 'black',
      color: 'red',
      margin: '4px',
    }
  }
  
  const props2 = {
    inputStyle: {
      height: '45px',
      margin: '4px',
      MozAppearance: 'textfield' as "textfield",
      backgroundColor: 'white',
      color: 'gray',
      fontFamily: 'Rubik',
      textAlign: "center" as "center",
      borderRadius: '10px',
      fontSize: '30px',
      width: '45px',
      border: '1px solid gray',
    },
    inputStyleInvalid: {
      margin: '4px',
      width: '40px',
      fontSize: '14px',
      backgroundColor: 'black',
      fontFamily: 'monospace',
      borderRadius: '3px',
      MozAppearance: 'textfield' as "textfield",
      height: '26px',
      border: '1px solid red',
      paddingLeft: '7px',
      color: 'red',
    }
  }

// Customizable Area End
export default class OTPVerification extends OTPVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  congratsDialogBox = () => {
    return (
        <CustomDialog
        aria-labelledby="alert-dialog-title"
        open={this.state.isDialogOpen}
        aria-describedby="alert-dialog-description"
        onClose={this.handleCloseDialog}
        >
            <DialogContent style={{ padding:"24px",textAlign: 'center', }}>
                <img src={image_Success} alt="Success" style={{borderRadius: "50%", width: "35%", marginBottom:"10px",marginTop:"20px" ,}} />
                <Typography gutterBottom style={webStyle.congratsText}>
                    Congratulations!
                </Typography>
                <Typography style={webStyle.profileCreatedText}>
                Your account has been created successfully.
                </Typography>
                <Box>
                    <button
                        onClick={this.handleHome}
                        style={webStyle.signInAccountStyle}
                    >
                        SIGN IN YOUR ACCOUNT
                    </button>
                </Box>
            </DialogContent>
        </CustomDialog>
    );
};

  // Customizable Area End
  render() {
     // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <MainContainer>
        <Grid container spacing={0}>
          <Grid container item xs={12} md={5} justifyContent="center">
            <Grid item xs={11} sm={9}>
            <img  onClick={this.handleLogoClick} style={webStyle.invento}src={Horizontalinventohub} data-test-id="handleLogoClick"/>
            <div style={webStyle.verifyStyle}>
              <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
              <Typography style={webStyle.otpTime}>Enter 6 Digit verification code sent to your email ID  - {this.state.clientEmail}</Typography>
              <div>
              <ReactCodeInputStyle
                type="number"
                onChange={this.handleOtpValue}
                fields={6}
                name={"VerifyOtp"}
                {...props}
                inputMode={"tel"}
                value={this.state.otpValue}
                />
                  <ReactCodeInputStyle2
                {...props2}
                type="number"
                inputMode={"tel"} 
                fields={6}
                name={"VerifyOtp"} 
                value={this.state.otpValue} 
                onChange={this.handleOtpValue}
                />
                </div>
                
                <div style={{ display: "flex", gap: "10px", marginTop: "24px", justifyContent: 'space-between', alignItems: 'center', width: '100%', maxWidth: 433 }} className="password-options">
                  <Typography
                    style={{
                      ...webStyle.otpTime,
                      width: "max-content"
                    }}
                  >
                    Enter Verification Code: {this.displayTime()}
                  </Typography>
                  <span
                    onClick={this.handleResendOtp}
                    data-testid="resend_emailotp"
                    tabIndex={0} 
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          this.handleResendOtp()          
                        }
                      }}   
                    style={{
                      ...webStyle.ResendStyle,
                      pointerEvents: this.state.disabledResendOtp ? "none" : "auto",
                      color: "#364BA0",
                    }}
                  >
                    Resend Code
                  </span>
                </div>


              <Box component={"div"} style={webStyle.topSpacing}>
                <FormControl style={{ maxWidth: 432,margin:"auto",width:"100%" }}>
                  <button
                    style={webStyle.nxtBtn}
                    onClick={this.handleVerifybox}
                    disabled={this.state.VerificationError}
                    tabIndex={0} 
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          this.handleVerifybox()          
                        }
                      }}   
                  >
                    VERIFY
                  </button>
                </FormControl>
              </Box>
              {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
              <Box margin={"30px 0"} component={"div"}>
                <span style={webStyle.haveAccount}>Have an account? </span>
                <span style={webStyle.signIn} onClick={this.handleSignIn}
                tabIndex={0} 
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    this.handleSignIn()          
                  }
                }}   
                >SIGN IN</span>
              </Box>
            </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} className="dots-img">
            <img src={login} style={webStyle.image} alt="Login" />
          </Grid>
        </Grid>
        </MainContainer>
      {this.state.isDialogOpen && <>
    {this.congratsDialogBox()}
  </>
  }
      </>
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const ReactCodeInputStyle=styled(ReactCodeInput)({
    "@media (max-width: 1305px)": {
  display:"none !important" 
    }
  })
  const ReactCodeInputStyle2=styled(ReactCodeInput)({
    display:"none !important" ,
    "@media (max-width: 1305px)": {
    display:"inline-block !important" 
  
    }
  })

const webStyle = {
    congratsText:{
  marginTop:"10px",
  fontSize: "22px",
  color: "#059669",
  fontWeight: 600,
  fontFamily: "Rubik"
},
signInAccountStyle:{
  borderRadius:"8px",
  marginBottom: '10px',
  background:"#364BA0",
  border:"1px solid #364BA0",
  color: "white",
  padding:"18px",
  width:"100%",
  fontFamily: "Rubik",
  cursor:"pointer",
  fontSize:"18px",
  fontWeight:500

},
profileCreatedText:
{
   marginBottom: '20px',
   color:"#011342",
  fontSize: "14px",
  fontWeight: 500,
  marginTop: "18px",
  fontFamily: "Rubik"
},
    starStyle:{
        fontWeight: 800,
        color: "#FF5E5B",
    },
      invento:{
        width:"264px",
        height:"48px",
        objectFit:"cover" as "cover",
         marginTop: "42px",
         marginLeft:"10px",
         marginBottom: "34px",
         cursor:"pointer"
      },
      verifyStyle: {
        display: 'flex',
        flexDirection: 'column' as 'column',
      },
      otpStyle: {
        marginTop: "80px",
        fontSize: "32px",
        fontWeight: 700,
        color: "#364BA0",
        fontFamily: "Rubik"
      },
      otpTime: {
        color: "#535353",
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: "Rubik",
      },
      ResendStyle:{
        color: "#364BA0",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 500,
        textDecoration: "underline",
        cursor: "pointer",

      },
      topSpacing: {
        marginTop: "16px"
      },
      nxtBtn: {
        padding: "16px", 
        borderRadius: "8px",
        marginBottom: '10px',
        backgroundColor: "#364BA0",
        color: 'white',
        border:"1px solid #364BA0",
        fontWeight: "bold" as "bold",
        fontFamily:"Rubik",fontSize:"16px",
        cursor:"pointer",
      },
      timer: {
        fontSize: "18px",
        fontFamily: "Inter",
        color: "red",
        marginTop: "10px"
      },
      haveAccount: {
        color: "#6F6E6E",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "40px"
      },
      signIn: {
        color: "#364BA0",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "40px",
        textDecoration: "underline",
        cursor: "pointer"
      },
      image: {
        position:"absolute" as "absolute",
        width: "50%",
        height: "auto",
        top: 0,
        objectPosition:"top right",
        right:0,
        "@media (max-width: 768px)": {
          display: "none"
        }
      },
}
// Customizable Area End