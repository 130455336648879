import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeButton: string;
    age:  string;
    open: boolean;
    showJobs:boolean;
    date:string;
    activeTab: any
    activeDetailsData: any;
    authToken:string
    value:any;
    uploadedFileName: any
    isOpenModal: boolean
    succesMessage: string
    milestoneDetailsData: any
    isOpenSubmitWorkModal: boolean
    milestoneupdate: boolean
    isOpenSubmitWorkModalId: any
    isOpenUploadDocCom: boolean
    selectedFiles: any
    anchorEl: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

  // Customizable Area Start
  // Customizable Area End

export default class FreelancerActiveContractController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contractDetailsId: string = ""
    getPostDocId: string = ""
    submitContractsId: string = ""
    uploadWorkId: string = ""
    milestoneDetailsId: string = ""
    // Customizable Area End
    constructor(props:Props){
        super(props) ;
        this.receive=this.receive.bind(this);
    this.subScribedMessages=[getName(MessageEnum.RestAPIResponceMessage)];
        // Customizable Area Start

        this.state={
            age: "",
            open: false,
            showJobs:false,
            date:"",
            activeButton: "offers",
            activeTab: 0,
            activeDetailsData:[], 
            authToken:'',
            value: '',
            uploadedFileName: '',
            isOpenModal: false,
            succesMessage: '',
            milestoneDetailsData:[{
          "id": "",
          "type": "",
          "attributes": {
            "id": 0,
            "description": "",
            "date": null,
            "status": "",
            "amount": "",
            "proposal_generation_id": 0,
            "is_project_ended": false,
            "upload_works": [
              {
                "work": "",
                "name": "",
                "type": ""
              },
                ]
          }
          }],
            isOpenSubmitWorkModal: false,
            isOpenSubmitWorkModalId: null,
            isOpenUploadDocCom : false,
            milestoneupdate:false,
            selectedFiles: [],
            anchorEl: null
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get('authToken'),
        });
        this.getActiveMilestoneDetails()
        this.getActiveContractDetails()
        this.getPostDocApiCall()
        // Customizable Area End
    }



    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
          
          // return;
        }
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const webApiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
      
          let webResponseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
         this.handleActiveContractRes(webResponseJson,webApiRequestCallId)
         this.handleSubmitProjectTrackingRes(webResponseJson,webApiRequestCallId)
         this.handleMilestoneContractRes(webResponseJson,webApiRequestCallId)
         this.handleUploadWorkRes(webResponseJson,webApiRequestCallId)
         this.handlegetPostDocRes(webResponseJson,webApiRequestCallId)
        }
        // Customizable Area End
      }
    // Customizable Area End
    
    // Customizable Area Start

                

      handleUploadWorkModal= (modalData:any, id: number) => {
        
        this.setState({
          isOpenSubmitWorkModal: true,
          selectedFiles: modalData.attributes?.upload_works,
          isOpenSubmitWorkModalId: id
        })
      }
      handleMilestoneDataupload = () => {
          this.setState({
            isOpenUploadDocCom: true
          })
        }
        getStatusColor = (status: string) => {
          switch (status) {
            case "completed":
              return "#348306";
            case "ongoing":
              return "#364BA0";
            case "pending":
              return "red";
            default:
              return "black";
          }
        };

              
      getPostDocApiCall = async () => {
    
        const header = {
            token: this.state.authToken,
            "Content-Type": webConfigJSON.validationApiContentType,
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getPostDocId = requestMessage.messageId;
        const paramId = this.props.navigation.getParam("id")
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/milestones/freelancer_get_post_documents?id=${paramId}`
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
        
      getActiveContractDetails = async () => {
    
        const header = {
            token: this.state.authToken,
            "Content-Type": webConfigJSON.validationApiContentType,
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.contractDetailsId = requestMessage.messageId;
        const paramId = this.props.navigation.getParam("id")
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/contracts/contract_details?id=${paramId}`
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

        handleSubmitProjectTrackingRes = (webResponseJson: { data: any; error: 'string',message: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.submitContractsId) {
                this.setState({
                    isOpenModal: true,
                    succesMessage: webResponseJson?.message
                })
              if(webResponseJson?.data){
                this.setState({
                    isOpenModal: true,
                    succesMessage: 'Milestone has been successfully updated',
                    milestoneupdate: true
                })
              }else{
                this.setState({
                    isOpenModal: true,
                    succesMessage: webResponseJson?.error,
                })
              }
            }
           
          }
      }

      handleMilestoneContractRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.milestoneDetailsId) {
              if(webResponseJson?.data){
                this.setState({ milestoneDetailsData: webResponseJson?.data })
              }
                         }
          }
      }

      handleChanges = (event: any ) => {
        this.setState({date: event.target.value as string
        })
    };

      handleUploadWorkRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.uploadWorkId) {
              if(webResponseJson?.error){
                this.setState({ 
                  succesMessage: webResponseJson?.error[0] ,
                  isOpenModal: true,
                })
              }
              if(webResponseJson?.data){
                this.setState({ 
                  isOpenSubmitWorkModal: false,
                  isOpenModal: true,
                  succesMessage: 'Documents uploaded sucessfully',
                  milestoneupdate: true
                })
              }
                         }
          }
      }
      
    handleActiveContractRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.contractDetailsId) {
              if(webResponseJson?.data){
                this.setState({ activeDetailsData: webResponseJson?.data })
              }
            }
          }
      }

      handlegetPostDocRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.getPostDocId) {
              if(webResponseJson?.data){
                this.setState({ activeDetailsData: webResponseJson?.data })
              }
            }
          }
      }

      handleCloseModal = () =>{
        this.setState({isOpenModal : false, 
          isOpenSubmitWorkModal: false,
          isOpenSubmitWorkModalId: null
        })
        this.getActiveMilestoneDetails();
      }
    
      getActiveMilestoneDetails=async()=>{
        const header={
          "Content-Type": webConfigJSON.validationApiContentType,
            token: this.state.authToken,
        }
    
        const requestMessage=new Message(
             getName(MessageEnum.RestAPIRequestMessage))
    
        const paramId=this.props.navigation.getParam("id")
        this.milestoneDetailsId=requestMessage.messageId
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/milestones/get_proposal_milestones?proposal_generation_id=${paramId}`)
  
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET")
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header))
    
        runEngine.sendMessage(requestMessage.id, requestMessage)
        return true;
    };
      handleInputChange = (index: number, field: string, value: string | unknown) => {
        this.setState((prevState) => {
          const updatedMilestones = [...prevState.milestoneDetailsData];
          if (updatedMilestones[index]) {
            updatedMilestones[index] = {
              ...updatedMilestones[index],
              attributes: {
                ...updatedMilestones[index].attributes,
                [field]: value,
              },
            };
          }
          return { milestoneDetailsData: updatedMilestones };
        });
      };
        
  

    handleOpen = () => {
        this.setState({ 
            open: true
         });
    };

      handleInputChangeS = (event: any) => {
        this.setState({ value: event.target.value });
      };
    
      handleFileUpload = (event: any, id: number) => {
        const file = event.target.files?.[0];
        if (file) {
          const updatedMilestoneDetails = [...this.state.milestoneDetailsData];
          const milestoneIndex = updatedMilestoneDetails.findIndex(
            (milestone) => String(milestone.id) === String(id)
          );
      
          if (milestoneIndex !== -1) {
            updatedMilestoneDetails[milestoneIndex].attributes.upload_works = [{ name: file.name, file }];
      
            this.setState({ milestoneDetailsData: updatedMilestoneDetails }, () => {
              this.handleUploadWorkApi(id);
            });
          } 
        } 
      };
   handleUploadWorkApi = (id: number) => {
        const formData = new FormData();
        this.state.selectedFiles?.forEach((file: any) => {
          formData.append(`milestone[upload_works][]`, file);
        });
        const header={
          "Content-Type": webConfigJSON.validationApiContentType,
            token: this.state.authToken,
        }
      
        const submitContracts = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.uploadWorkId = submitContracts.messageId;
      
        submitContracts.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_contract/milestones/${id}/upload_work_attachments`
        );
        submitContracts.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), 
        JSON.stringify(header));
        submitContracts.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        submitContracts.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PATCH');
      
        runEngine.sendMessage(submitContracts.id, submitContracts);
        return true;
      };
      
      handleRemoveFile = (name: string) => {
        let itemIndex = this.state.selectedFiles.findIndex((item:any) => item.name.includes(name))
        this.state.selectedFiles.splice(itemIndex,1)
        this.setState({selectedFiles:this.state.selectedFiles});
      };
      
       handleAttachClick = () => {
        const fileInput = document.getElementById("file-upload-input") as HTMLInputElement;
        if (fileInput) {
          fileInput.click();
        }
  };

  handleStatusChange = (event: { target: { value: any; }; }) => {
    this.setState({
      activeDetailsData: {
        ...this.state.activeDetailsData,
        attributes: {
          ...this.state.activeDetailsData.attributes,
          status: event.target.value
        }
      }
    });
  };
  
  handleFileChange = (event: any) => {
    const files = Array.from(event.target.files);
  
    const validFiles = files.filter((file: any) => file.size <= 5 * 1024 * 1024);
  
    const selectedFiles = Array.isArray(this.state.selectedFiles) ? this.state.selectedFiles : [];
  
    const newFiles = validFiles.filter(
      (file: any) =>
        !selectedFiles.some((existingFile: any) => existingFile.name === file.name)
    );
  
    const updatedFiles = [...selectedFiles, ...newFiles].slice(0, 5);
  
    this.setState({ selectedFiles: updatedFiles });
  };
  
  
    
      handleTabChange = (event: any, newValue: number) => {
        this.setState({ activeTab: newValue }, () => {
            this.getActiveMilestoneDetails();
            this.getActiveContractDetails()
        });
    };
    
    handleSubmitMilestone = (id: any, milestoneData: any) => {

      const submitContracts = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      const header = {
        "token": this.state.authToken
      };
    
      const formData = new FormData();
      formData.append('milestone[description]', milestoneData.description);
      formData.append('milestone[date]', milestoneData.date);
      formData.append('milestone[status]', milestoneData.status);
      
    
      this.submitContractsId = submitContracts.messageId;
      submitContracts.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_contract/milestones/${id}/update_milestone_by_freelancer`
      );
      submitContracts.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      submitContracts.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData 
      );
      submitContracts.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PATCH'
      );
      runEngine.sendMessage(submitContracts.id, submitContracts);
    };

    handleDeleteAllFiles = () => {
      this.setState({
        selectedFiles:[]
      })
    }

    handleMenuOpen = (event: any) => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleMenuClose = () => {
      this.setState({ anchorEl: null });
    };
  


    // Customizable Area End
}

     // Customizable Area Start
    // Customizable Area End  
