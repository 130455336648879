import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Divider,
    Tab,
    OutlinedInput,
    Button,
    InputAdornment,
    IconButton,
    Dialog,
    DialogContent,
    List,
    FormControl,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Menu,
    TableContainer,
    Paper,
    TextField,
} from "@material-ui/core";
import FooterWeb from "./Components/Footer.web";
import Tabs from "@material-ui/core/Tabs";
import FreelancerNavBar from "./FreelancerNavBar.web";
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GetAppIcon from '@material-ui/icons/GetApp';


const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }

})
import FreelancerActiveContractController, {
    Props,
    webConfigJSON,
} from "./FreelancerActiveContractController.web";
import { image_Success, uploadDoc } from "../../email-account-registration/src/assets";
import { fileFolder } from "./assets";

// Customizable Area End

export default class FreelancerActiveContract extends FreelancerActiveContractController {
    constructor(props:Props){
        super(props) ;
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    renderSelect=()=>{
        return(
            <Box  style={{ display: "flex" }} >
                <CurUpperText>
                    {webConfigJSON.myJobs}
                </CurUpperText>
                <ArrowIcon>
                    {">"}
                </ArrowIcon>
                <CurUpperText>
                    {webConfigJSON.allcontract}
                </CurUpperText>
                <ArrowIcon>
                    {">"}
                </ArrowIcon>
                <FontActiveText>
                    {webConfigJSON.ArchivedContract}
                </FontActiveText>
            </Box>
        )};

        renderUploadWorkModal = () =>{
          const { isOpenSubmitWorkModalId } = this.state;
            return(
              <>
                 <CustomDialogBox
              open={this.state.isOpenSubmitWorkModal}
              onClose={this.handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
               
              <DialogContent style={{
                 textAlign: 'center',padding:"24px" }}>
            
                   <Box style={webStyle.editContainer}>
                                    <Typography style={{ fontSize: "24px",
                                      color: 'black',
                                      fontFamily: 'Rubik',
                                      fontWeight: 600
                                     }}>Submit Work</Typography>
                                    <IconButton aria-label="close" onClick={this.handleCloseModal}>
                                        <CloseIcon style={{
                                            color: 'black'
                                        }} />
                                    </IconButton>
                                </Box>
                                <hr style={webStyle.Hr} />
                  <Typography 
                  style={{
                    marginTop: '32px',
                    fontSize: '20px',
                    color: '#0A0A0A',
                    fontWeight: 700,
                    fontFamily: 'Rubik',
                    marginBottom: '12px',
                    display: 'flex'
                  }}
                  >
                     Upload 
                      </Typography>
                      <Box
  style={{
    border: "2px dashed #364BA0",
    borderRadius: "8px",
    padding: "24px 20px 34px",
    textAlign: "center",
    width: 'auto',
    maxWidth: 'auto',
    backgroundColor: "#F7F9FA",
    cursor: "pointer",
  }}
  onClick={() => document.getElementById('file-upload-input')?.click()} 
>
  <div
    className="imgCenter"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img
      src={uploadDoc}
      alt="upload"
      style={{
        width: "29px",
        height: "29px",
      }}
    />
    <input
      id="file-upload-input"
      type="file"
      multiple
      accept=".doc,.docx,.pdf,image/*"
      hidden
      onChange={this.handleFileChange} 
    />
  </div>
  <Typography
    style={{
      marginTop: '24px',
      fontSize: '16px',
      fontFamily: 'Rubik',
      color: '#535353',
      fontWeight: 600,
    }}
  >
    Attach
  </Typography>
  <Typography
    style={{
      marginTop: '12px',
      fontSize: '16px',
      fontFamily: 'Rubik',
      color: '#535353',
    }}
  >
    Max 5 files, 5 MB per file
  </Typography>
</Box>
<Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "32px",
          gap: "16px",
        }}
      >
                      {this.state.selectedFiles?.length > 0 && (
                        <Box>
                          <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {this.state.selectedFiles.map((file: any, index: number) => {
                                  return <Box>
                                    {(file?.work && file?.type && file?.name) &&
                                      (
                                        <>
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              marginBottom: "8px",
                                            }}>
                                            <Box style={{ display: "block", margin: "auto", textTransform: "uppercase" }}>
                                              <img
                                                src={file?.work}
                                                alt={"Uploaded file"}
                                                style={{
                                                  objectFit: "cover",
                                                  display: "block",
                                                  margin: "auto",
                                                  borderRadius: "4px",
                                                  width: "184px",
                                                  height: "150px",
                                                }}
                                              />
                                            </Box>
                                            <IconButton
                                            data-test-id="remove-btn"
                                              onClick={() => this.handleRemoveFile(file?.name)}
                                              size="small"
                                              style={{
                                                left: "-17%",
                                                background: "red",
                                                color: "white",
                                                fontWeight: 600,
                                                height: "40px",
                                                width: "40px",
                                                borderRadius: '10px'
                                              }}
                                            >
                                              <CloseIcon style={{ fontSize: "25px" }} />
                                            </IconButton>
                                          </Box>
                                        </>
                                      )}
                                      {(!file?.work && file?.type && file?.name) && (
                                  <>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // padding: "8px 16px",
                                        marginBottom: "8px",
                                      }}>
                                      <Box style={{ display: "block", margin: "auto", textTransform: "uppercase" }}>
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={file?.name || "Uploaded file"}
                                          style={{
                                            objectFit: "cover",
                                            display: "block",
                                            margin: "auto",
                                            borderRadius: "4px",
                                            width: "184px",
                                            height: "150px",
                                          }}
                                        />
                                      </Box>
                                      <IconButton
                                      data-test-id="removeFile"
                                        onClick={() => this.handleRemoveFile(file.name)}
                                        size="small"
                                        style={{
                                          left: "-17%",
                                          background: "red",
                                          color: "white",
                                          fontWeight: 600,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <CloseIcon style={{ fontSize: "25px" }} />
                                      </IconButton>
                                    </Box>
                                  </>
                                )}
                                  </Box>
                                })}
                          </List>
                        </Box>
                      )}
      </Box>
                  <Box style={{ 
                    display: "flex",
                    justifyContent: 'space-between',
                    marginTop: '32px',
                    flexWrap: 'wrap'
                  }}>
                    <Box>
                      <Button 
                      onClick={this.handleDeleteAllFiles}
                      style={{
                        color:'#DF0004',
                        fontSize: '16px',
                        fontFamily: 'Rubik',
                        textDecoration: 'underline'
                      }}>
                        Delete
                      </Button>
                    </Box>
                    <Box style={{
                      gap: '16px',
                      display: 'flex',
                    }}>
                      <Button
                          variant="outlined"
                          style={{...webStyle.cancelButton,
                            padding: '22px 40px'
                          }}
                          onClick={this.handleCloseModal}
                      >
                         CANCEL
                      </Button>
                      <Button
                          variant="outlined"
                          style={{...webStyle.blueButton,
                             padding: '22px 40px'
                          }}
                          data-test-id="upload-work"
                          onClick={()=>this.handleUploadWorkApi(isOpenSubmitWorkModalId)}
                      >
                         ADD
                      </Button>
                      </Box>
                  </Box>
              </DialogContent>
          </CustomDialogBox>
              </>
            )
          }

        renderSuccessModal = () =>{
            return(
              <>
                 <CustomSucessDialogBox
              open={this.state.isOpenModal}
              onClose={this.handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
               
              <DialogContent style={{
                 textAlign: 'center',padding:"24px" }}>
                  {this.state.milestoneupdate && 
                  <>
                  <img 
                  src={image_Success}
                   alt="Success" 
                   style={{ width: "35%",
                   borderRadius: "50%",
                   marginTop:"20px" ,marginBottom:"10px"}} />
                  <Typography 
                  gutterBottom style={webStyle.congratsText}>
                      Congratulations!  
                      </Typography>
                      </>
                      }
                  <Typography style={{...webStyle.profileCreatedText, 
                  textTransform: 'capitalize'
                  }}>
                 {this.state.succesMessage}</Typography>
                  <Box style={{ display: "flex",
                   flexDirection: "column" }}>
                      <Button
                          variant="outlined"
                          style={webStyle.blueButton}
                          onClick={this.handleCloseModal}
                      >
                         OK
                      </Button>
                  </Box>
              </DialogContent>
          </CustomSucessDialogBox>
              </>
            )
          }

          renderTabContent = () => {
            const { activeTab } = this.state;
        
            if (activeTab === 0) {
                return (
                    <>
                        {this.renderProject()}
                        {this.renderButtons()}
                    </>
                );
            } else if (activeTab === 1) {
                return (
                    <>
                        {this.renderProjectTracking()}
                        {this.renderButtons()}
                    </>
                );
            } else {
                return (
                    <>
                        {this.renderProjectDocuments()}
                        {this.renderButtons()}
                    </>
                );
            }
        };

        
    renderContract=()=> {
        const {activeTab} = this.state
        return(
            <>
            <Box>
                <HeaderTxt>
                   Active Contracts
                </HeaderTxt>
                <TitleTxt>Stay focused and informed with a detailed view of your active contract. Monitor progress, manage tasks, and keep all important information at your fingertips for seamless project execution.

                </TitleTxt>
                <CustomTabs value={activeTab} indicatorColor="primary"
                data-test-id='tabs'
                    onChange={this.handleTabChange} textColor="primary">
                    <Tab 
                    label="Project Details" 
                    style={{ textTransform: "none", 
                    fontFamily: "Rubik", 
                    fontSize: "16px", 
                    color: this.state.activeTab === 0 ? '#364BA0' : '#3F3F3F',
                    fontWeight: 500 }} />
                    <Tab 
                    label="Project Tracking" 
                    style={{ textTransform: "none", 
                    fontFamily: "Rubik", 
                    fontSize: "16px",
                    color: this.state.activeTab === 1 ? '#364BA0' : '#3F3F3F',
                    fontWeight: 500 }} />
                      <Tab 
                    label="Project Document" 
                    style={{ textTransform: "none", 
                    fontFamily: "Rubik", 
                    fontSize: "16px",
                    color: this.state.activeTab === 2 ? '#364BA0' : '#3F3F3F',
                    fontWeight: 500 }} />
                </CustomTabs>
                <Divider />
              
            </Box>
            {this.renderTabContent()}
                           </>
        );
    };


renderProjectTracking = () => {
    return (
        <ProjectOuterMainBox style={webStyle.boxStyle}>
            <Box>
                <Typography style={{
                   color: '0A0A0A',
                   fontSize: '16px',
                   fontWeight: 400,
                   fontFamily: 'Rubik',
                   padding: '24px 24px 0px 24px'
                }}>
                Efficiently track your project's progress with real-time updates and detailed overviews. Stay on top of deadlines, manage tasks, and ensure every aspect of your project is moving forward smoothly.
                </Typography>
            </Box>
            <Box style={{
            borderBottom: '1px solid #ECECEC',
            height: '1px',
            width: '100%'
         }}> </Box>
                 {this.renderMilestoneTracking()}
        </ProjectOuterMainBox>
    );
}

renderDocumentsTracking = () => {

  const fileData = [
    { title: "Flow Of Project.pdf", date: "27/05/24", owner: "Vikas" },
    { title: "Flow Of Project.doc", date: "04/06/24", owner: "Vikas" },
    { title: "Flow Of Project.doc", date: "04/06/24", owner: "Freelancer" },
    { title: "Flow Of Project.pdf", date: "05/06/24", owner: "Vikas" },
    { title: "Flow Of Project.doc", date: "27/05/24", owner: "Freelancer" },
  ];
  return(
    <MilestoneBox style={{padding: '0px 30px 30px 30px', width: '100%'}}>
      <CustomTitle >
        <Box className="milestonDocumentsBox">
    <Typography style={{
      color: '#3F3F3F',
      fontSize: '24px',
      fontFamily: 'Rubik',
      fontWeight: 700,
    }}
    >Documents </Typography>
    <Box 
    style={{
      display: 'flex',
      gap: '16px',
    }}>
    <CustomSearchField data-testid="search" placeholder="Search (Maximum file size of 2MB)"
                  inputProps={{
                    style: {...webStyle.outlinedInput,width: '100%'}}}
                  InputProps = {{
                    style: {
                      flex: 1,
                      borderRadius: '13px',
                      width: '100%',
                    } ,
                    startAdornment : (
                      <InputAdornment 
                      position="start"><SearchIcon /></InputAdornment>
                    )}}
                    name="search"
                  variant="outlined"/>
     <Button data-testid="dailog-cancel"
                    className="uploadBtn"
                    onClick={this.handleMilestoneDataupload}
                    ><GetAppIcon 
                    fontSize="small" 
                    /> Upload Document </Button>
    </Box></Box></CustomTitle>
      <TableContainer component={Paper} style={{ maxHeight: "80vh", overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="tablehead">Title</TableCell>
              <TableCell className="tablehead">Date and Time</TableCell>
              <TableCell className="tablehead">Owner</TableCell>
              <TableCell className="tablehead" align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileData.map((file, index) => (
              <TableRow key={index}>
                <TableCell className="tablesubtitle">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={fileFolder}
                      alt="file-icon"
                      style={{ marginRight: "20px" }}
                    />
                    {file.title}
                  </div>
                </TableCell>
                <TableCell className="tablesubtitle">{file.date}</TableCell>
                <TableCell className="tablesubtitle">{file.owner}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={this.handleMenuOpen}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={this.handleMenuClose}>Download</MenuItem>
        </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
</MilestoneBox>
  )
}

renderMilestoneDocTracking = () => {

   return (
       <MilestoneBox style={{padding: '0px 30px 30px 30px', width: '100%'}}>
                   <CustomTitle >
        <Box className="milestonDocumentsBox">
    <Typography style={{
      color: '#3F3F3F',
      fontSize: '24px',
      fontFamily: 'Rubik',
      fontWeight: 700,
    }}
    >Milestone Documents</Typography>
    <Box style={{gap: '16px',
      display: 'flex'
    }}>
    <CustomSearchField
                  data-testid="search"
                  placeholder="Search (Maximum file size of 2MB)"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput,
                      width: '100%'
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '100%',
                      flex: 1
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  // value={this.state.search}
                  name="search"
                  // onChange={this.handleChange}
                />
     <Button
                      data-testid="dailog-cancel"
                      // onClick={() => this.props.navigation.navigate('FreelancerRaiseDisputes', {id: paramId })}
                    className="uploadBtn">
                    <GetAppIcon fontSize="small" />
                    Upload Document
                    </Button>
    </Box>
    </Box>
      </CustomTitle>

           <Box>
           <Box style={{ margin: "20px", width: "100%" }}>
     <Box>
       <Box >
       {this.state.milestoneDetailsData?.map((milestone: any, index: number) => {
        const { id, date, upload_works } = milestone?.attributes;
        return (
          <>
             <Box className="milestoneBox" style={{gap: '40px'}} key={id}>
              
               <Box 
               className="inputBoxDes">
             <Typography 
             style={webStyle.tableHeading}
             >Milestone Document
             </Typography>
             <Box
              style={{ display: 'flex' }}>
                 <OutlinedInput fullWidth
                 value={upload_works.map((work: any) => work?.name).join(',') || ''} multiline
                 inputProps={{
                   style: {
                     fontWeight: 500,
                       fontFamily: 'Rubik',
                       fontSize: '16px',
                     color: '#515354',
                     } }}
                 endAdornment={
                 <InputAdornment 
                 position="end">
                   <Button onClick={() => {this.handleUploadWorkModal(milestone, id)}}
                     component="label"
                   > 
                   <GetAppIcon 
                   fontSize="small" 
                   />
                   </Button> </InputAdornment>}/>
             </Box> </Box>
 
               {/* Submission Date */}
               <Box className="inputBoxField">
                 <Typography style={webStyle.tableHeading}>Submission Date</Typography>
                 <OutlinedInput
                   fullWidth
                   value={date}
                   onChange={(e) =>
                     this.handleInputChange(index, 'date', e.target.value)
                   }
                   inputProps={{
                     style: {
                       fontWeight: 500,
                       color: '#515354',
                       fontSize: '16px',
                       fontFamily: 'Rubik',
                     },
                   }}
                 />
               </Box>
 
       
 
               {/* Status */}
               <Box className="inputBoxField">
                 <Typography style={webStyle.tableHeading}>Owner</Typography>
                 <OutlinedInput
                   fullWidth
                   value={date}
                   onChange={(e) =>
                     this.handleInputChange(index, 'date', e.target.value)
                   }
                   inputProps={{
                     style: {
                       fontWeight: 500,
                       color: '#515354',
                       fontSize: '16px',
                       fontFamily: 'Rubik',
                     },
                   }}
                 />
               </Box>
 
             </Box>
          </>
           );
      })}

       </Box>
     </Box>
   </Box>
           </Box>
   
       </MilestoneBox>
   );
};

renderProjectDocuments = () => {
  return (
      <ProjectOuterMainBox style={webStyle.boxStyle}>
          <Box>
              <Typography style={{
                 color: '0A0A0A',
                 fontSize: '16px',
                 fontWeight: 400,
                 fontFamily: 'Rubik',
                 padding: '24px 24px 0px 24px'
              }}>
              Efficiently track your project's progress with real-time updates and detailed overviews. Stay on top of deadlines, manage tasks, and ensure every aspect of your project is moving forward smoothly.
              </Typography>
          </Box>
          <Box style={{
          borderBottom: '1px solid #ECECEC',
          height: '1px',
          width: '100%'
       }}> </Box>
       {this.state.isOpenUploadDocCom ? 
       this.renderMilestoneDocTracking() : 
       this.renderDocumentsTracking()
       }
      </ProjectOuterMainBox>
  );
}

renderProject = () => {
    return (
        <ProjectOuterMainBox style={webStyle.boxStyle}>
          <Box className="projectDes">
            <Typography style={{
                color: '#303030',
                fontSize: '24px',
                fontFamily: 'Rubik',
                fontWeight: 700,
                marginBottom: '41px',
                textTransform: 'capitalize'
            }}>{this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.name}</Typography>
            <Box >
                <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >IP Client:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>{this.state.activeDetailsData?.attributes?.client_details?.client_name}</Typography>
                </Box>
                <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >Duration:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>{this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.project_length}</Typography>
                </Box> <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >Charge:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>${this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.est_budget}.00</Typography>
                </Box> 
                <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >Payment:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>
                {this.state.activeDetailsData?.attributes?.payment_type === 'by_milestone' ? 'By Milestones': 'By Project'}</Typography>
                </Box>
            </Box>
          </Box>
         <Box style={{
            border: '1px solid #ECECEC',
            height: '20px',
            width: '100%'
         }}> </Box>
          <Box style={{paddingLeft: '30px'}}>
            <Typography
            style={{
                color: '#3F3F3F',
                fontSize: '24px',
                fontFamily: 'Rubik',
                fontWeight: 700
            }}
            >Project Details</Typography>
            <Typography style={{
                color: '#303030',
                fontSize: '16px',
                fontFamily: 'Rubik',
            }}>{this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.job_description}</Typography>
          </Box>
          <Box style={{
            borderBottom: '1px solid #ECECEC',
            height: '1px',
            width: '100%'
         }}> </Box>
          {this.renderMilestone()}
        </ProjectOuterMainBox>
    );
}

renderButtons = () => {
    const paramId = this.props.navigation.getParam("id")
    return(
        <CustomButton >
        <Box style={{marginTop: '48px'}}>
                   <Button
                      data-testid="dailog-cancel-btn"
                      onClick={() => this.props.navigation.navigate('FreelancerRaiseDisputes', {id: paramId })}
                    className="raisedisputeBtn">
                      Raise Dispute
                    </Button>
                    <Button
                      variant="contained"
                      className="alldisputeBtn"
                      color="primary"
                      data-testid="dailog-withdraw-proposal-btn"
                      onClick={() => this.props.navigation.navigate('FreelancerAllDisputes', {id: paramId })}
                    >
                      All Disputes
                    </Button>
                    </Box>
                    <Box style={{marginTop: '48px'}}>
                    <Button
                      variant="contained"
                      className="endisputeBtn"
                      data-testid="dailog-withdraw-proposal-btn"
                    //   onClick={this.handleWithdrawProposals}
                    >
                      End Contract
                    </Button>
                    </Box>
        </CustomButton>
    )
}

renderMilestoneTracking = () => {

     return (
         <MilestoneBox style={{padding: '0px 30px 30px 30px', width: '100%'}}>
                     <Typography style={{
                    color: '#3F3F3F',
                    fontSize: '24px',
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                    marginBottom: '62px'
             }}
             >Milestone Tracking</Typography>

             <Box>
             <Box style={{ margin: "20px", width: "100%" }}>
       <Box>
         <Box >
         {this.state.milestoneDetailsData?.map((milestone: any, index: number) => {
          const { id, description, date, status, upload_works } = milestone?.attributes;
          return (
            <>
               <Box className="milestoneBox" key={id}>
                
                 {/* Milestone Description */}
                 <Box className="inputBoxDes">
                   <Typography style={webStyle.tableHeading}>Milestone Description</Typography>
                   <Box style={{ display: 'flex' }}>
                     <Typography
                       style={{
                         ...webStyle.tableHeading,
                         marginTop: '17px',
                         color: '#515354',
                         marginRight: '10px',
                       }}
                     >
                       {index + 1}
                     </Typography>
                     <OutlinedInput
                       fullWidth
                       value={description}
                       onChange={(e) =>
                         this.handleInputChange(index, 'description', e.target.value)
                       }
                       data-test-id="descrption"
                       inputProps={{
                         style: {
                           fontWeight: 500,
                           color: '#515354',
                           fontSize: '16px',
                           fontFamily: 'Rubik',
                         },
                       }}
                     />
                   </Box>
                 </Box>
   
                 {/* Submission Date */}
                 <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Submission Date</Typography>
                   <OutlinedInput
                     fullWidth
                     value={date}
                     onChange={(e) =>
                       this.handleInputChange(index, 'date', e.target.value)
                     }
                     inputProps={{
                       style: {
                         fontWeight: 500,
                         color: '#515354',
                         fontSize: '16px',
                         fontFamily: 'Rubik',
                       },
                     }}
                   />
                 </Box> <Box 
                 className="inputBoxDes">
               <Typography
                style={webStyle.tableHeading}>
                  Upload Work</Typography>
               <Box 
               style={{ display: 'flex' }}>
                   <OutlinedInput
                   multiline
                   fullWidth
                   value={upload_works.map((work: any) => work?.name).join(',') || ''}
                   inputProps={{ style: {
                         fontFamily: 'Rubik',
                         color: '#515354',
                         fontWeight: 500,
                       fontSize: '16px',
                       }}} endAdornment={<InputAdornment
                    position="end">
                     <Button onClick={()=>{this.handleUploadWorkModal(milestone, id)}} component="label" >
                       <CloudUploadIcon />
                     </Button>
                   </InputAdornment>
                   }
                   />
               </Box>
               </Box>
   
                 {/* Status */}
                 <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Status</Typography>
                   <FormControl fullWidth>
             <Select
             variant="outlined"
               labelId="status-label"
               value={status}
               onChange={(e) =>
                 this.handleInputChange(index, 'status', e.target.value)
               }
               name="status"
               style={{...webStyle.customSelect,
                color: this.getStatusColor(status),
                fontSize: '16px',
                fontFamily: 'Rubik',
                fontWeight: 600,
               }}
               inputProps={{
                 style: {
                   fontWeight: 400,
                   color: this.getStatusColor(status),
                   fontSize: '16px',
                   fontFamily: 'Rubik',
                 }
               }}
               data-testid="project-btn"
             >
               <MenuItem value="ongoing">Ongoing</MenuItem>
               <MenuItem value="completed">Completed</MenuItem>
               <MenuItem value="pending">Pending</MenuItem>
             </Select>
           </FormControl>
                 </Box>
   
                 {/* Action */}
                 <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Action</Typography>
                   <CustomButton>
                     <Button
                       disabled={status === 'completed'}
                       data-testid="dailog-cancel-btn"
                       onClick={() => this.handleSubmitMilestone(id, milestone?.attributes)}
                       className="raisedisputeBtn"
                       style={{
                        backgroundColor: status === 'completed'? 'D8D8D8' : '#364BA0',
                        color: status === 'completed'? '#656565' : 'white',
                        border: status === 'completed'? '#656565' : '1px solid #364BA0',
                       }}
                     >
                       Submit
                     </Button>
                   </CustomButton>
                 </Box>
               </Box>
            </>
             );
        })}

         </Box>
       </Box>
     </Box>
             </Box>
     
         </MilestoneBox>
     );
 };

renderMilestone = () => {

    return (
        <MilestoneBox style={{padding: '30px'}}>
            <Typography style={{
                   color: '#3F3F3F',
                   fontSize: '24px',
                   fontFamily: 'Rubik',
                   fontWeight: 700,
                   marginBottom: '62px'
            }}
            >Milestone details</Typography>
            <Box>
            <Box style={{ margin: "20px", width: "100%" }}>
      <Box>
        <Box >
          {this.state.activeDetailsData?.attributes?.milestone_details?.map((milestone: any, index: number) => {
           return(
           <Box className={'milestoneBox'} key={milestone.id}>
                <Box className="inputBoxDes">
                  <Typography style={webStyle.tableHeading}>Milestone Description</Typography>
                  <Box style={{display: 'flex'}}>
                  <Typography style={{...webStyle.tableHeading, marginTop: '17px', color:'#515354',
                    marginRight: '10px'
                  }}>{index+1}</Typography>
                 <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_description}
                  /* onChange={this.handleProjectBudget} */
                  name="total_price_of_project"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                    </Box>
                  </Box>
                  <Box className="inputBoxField">
                 <Typography style={webStyle.tableHeading}>Budget</Typography>
                  <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_amount}
                  /* onChange={this.handleProjectBudget} */
                  name="budget"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Date</Typography>
                    <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_date}
                  className="outlinedInputBox"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                      borderRadius:'12px'
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Status</Typography>
                    <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_status}
                  /* onChange={this.handleProjectBudget} */
                  name="status"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: this.getStatusColor(milestone.milestone_status),
                      fontSize: '16px',              
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Active Disputes</Typography>
                   <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={this.state.activeDetailsData?.attributes?.active_disputes || 0}
                  /* onChange={this.handleProjectBudget} */
                  name="status"
                  inputProps = {{
                    style :{
                      fontWeight: 400,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                    <Typography style={webStyle.tableHeading}>Total Disputes</Typography>
                     <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={this.state.activeDetailsData?.attributes?.total_disputes || 0}
                  /* onChange={this.handleProjectBudget} */
                  name="status"
                  inputProps = {{
                    style :{
                      fontWeight: 400,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
            </Box>)
})}
        </Box>
      </Box>
    </Box>
            </Box>
            <Divider />
          
        </MilestoneBox>
    );
};


render() {
    //Customizable Area Start
  
    return (
        <>
            <FreelancerNavBar navigation={this.props.navigation} />
            {this.renderSuccessModal()}
            {this.renderUploadWorkModal()}

            <BoxMain>
                {this.renderContract()}
            </BoxMain>
            <FooterWeb />
        </>
    );
    //Customizable Area End
}

}

// Customizable Area Start
const webStyle = {
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '16px',
      lineHeight: '30px'
    }
  },
  customSelect: {
    fontFamily: 'Rubik',
    fontSize:'14px',
    color:'#4F4F4F',
    borderRadius: '8px',
    padding: '8px',
    height: '59px',
  },
    Hr: {
        height: '2px',
        background: '#DADADA',
        border: 'none',
        margin: '0'
    },
    editContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // padding: '0px 4px 0px 15px'
    },
    clientLeftAtrribute:{
        color: '#3F3F3F',
        fontSize: '18px',
        fontWeight: 700,
        width: '30%',
        fontFamily:'Rubik',
        marginBottom: '6px'
    },
    congratsText: {
      marginTop:"10px",
      fontWeight: 600,
      fontFamily: "Rubik",
      fontSize: "22px",
      color: "#059669",
    },
    profileCreatedText:{
        marginBottom: '20px',
        marginTop: "18px",
        fontFamily: "Rubik",
     fontSize: "14px",
     fontWeight: 500,
     },
     cancelButton:{
      color: '#364BA0',
      height:"40px",
      borderRadius:"10px",
      borderColor: '#364BA0',
      padding:"22px",
      backgroundColor: '#fff',
      fontSize:"18px",
      fontFamily:"Rubik",
      marginBottom: '10px',
      fontWeight:500,
      width: '50%',
      margin: 'auto'
    },
     blueButton:{
        backgroundColor: '#364BA0',
        height:"40px",
        borderRadius:"10px",
        borderColor: '#364BA0',
        padding:"22px",
        color: '#fff',
        fontSize:"18px",
        fontFamily:"Rubik",
        marginBottom: '10px',
        fontWeight:500,
        width: '50%',
        margin: 'auto'
      },
    clientRightAtrribute:{
        color: '#545454',
        fontSize: '18px',
        fontFamily:'Rubik',
        width: '70%',
        marginBottom: '6px'
    },
    arrowIcone: {
        color: 'white'
    },
fixedfiled: {
    margin: "22px 0px"
},
tableHeading:{
    fontSize: '16px',
    fontFamily: 'Rubik',
    color:'#000000',
    fontWeight: 600,
    marginBottom: '16px'
},
inputBox: {
    width: '60%'
},
calenderStyle: {
    width: "14px",
    height: "15px",
    marginRight: "5px"
},
boxStyle: {
    borderRadius: '16px',
    marginTop: '32px',
    border: '1px solid #CDCDCD',
}  

};

const BoxMain = styled(Box)({
    margin: "60px",
});

const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const FontActiveText = styled(Typography)({
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: "17px",
    textAlign: "left",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#206FC4",
});

const CurUpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});

const CustomButton = styled(Box)({
   display: 'flex', 
   justifyContent: 'space-between',
   "@media(max-width:600px)": {
    display: 'block', 
},
'& .endisputeBtn':{
    backgroundColor: '#D60000',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #D60000',
    "@media(max-width:600px)": {
        margin: 'auto' ,
    display: 'flex',

    },
},
'& .raisedisputeBtn' : {
    backgroundColor: '#364BA0',
    marginRight: '20px',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #364BA0',
    "@media(max-width:600px)": {
        margin: 'auto',
        display: 'flex',
        marginBottom: '40px'
    },
},
"& .alldisputeBtn":{
    backgroundColor: 'white',
    color: '#364BA0',
    fontSize: '18px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #364BA0',
    "@media(max-width:600px)": {
        margin: 'auto' ,
        display: 'flex'
    },
}
});

const HeaderTxt = styled(Typography)({
    color: "3F3F3F",
    fontWeight: "bold",
    fontSize: "36px",
    fontFamily: "Rubik",
});

const ProjectOuterMainBox = styled(Box)({
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "30px",
    '& .projectDes':{
        width: '50%', 
        paddingLeft: '30px',
        paddingTop: '30px',
        "@media(max-width:600px)": {
         width: '100%'
        }, 
    '& .projectContent' :{
        display:'flex', 
        gap:'100px',
        "@media(max-width:600px)": {
            display:'block', 
           },
    } 
    }
});

const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontFamily: "Rubik, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#595959",
    flexWrap: 'wrap'
});

const CustomSearchField = styled(TextField)({
  width:'420px',
  '& .MuiInputBase-input::placeholder': {
    color: '#515354',
    fontSize: '18px',
    fontFamily: 'Rubik',
    fontWeight: 400
  },
});

const CustomTitle = styled(Box)({
  '& .milestonDocumentsBox':{
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '62px'

  },
  '& .uploadBtn' : {
    backgroundColor: '#364BA0',
    marginRight: '20px',
    color: 'white',
    fontSize: '16px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #364BA0',
    "@media(max-width:600px)": {
        margin: 'auto',
        display: 'flex',
        marginBottom: '40px'
    },
},
});


const MilestoneBox = styled(Box)({
  '& .oulinedInputBox':{
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px'
    }
  },
  '& .tablehead':{
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Rubik',
    color: '#000000'
  },
  '& .tablesubtitle':{
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Rubik',
    color: '#515354'
  },
   '& .milestoneBox': {
        display: 'flex',
        gap: '10px',
         "@media(max-width:1080px)": {
            display: 'block',
        },       
    },
    '& .inputBoxDes': {
        width: '80%',
        marginBottom: '20px',
        "@media(max-width:1080px)": {
            width: '100%',
        }, 
    },
    '& .inputBoxField':{
        width: '60%',
        marginBottom: '20px',
        "@media(max-width:1080px)": {
            width: '100%',
        },
    }
  });

  const CustomSucessDialogBox = styled(Dialog)({
    '& .MuiPaper-rounded':{
      width:"982px",
      borderRadius: "18px",
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '600px'
    }
  })

  const CustomDialogBox = styled(Dialog)({
    '& .MuiPaper-rounded':{
      width:"982px",
      borderRadius: "18px",
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '982px'
    }
  })

// Customizable Area End