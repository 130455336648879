import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeButton: string;
    age:  string;
    open: boolean;
    showJobs:boolean;
    date:string;
    activeTab: any
    selectMilestones: string;
    authToken:string
    value:any;
    uploadedFileName: any
    isOpenModal: boolean
    succesMessage: string
    milestoneupdate: boolean
    allDisputesData: any
    allDetailsDisputesData: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

  // Customizable Area Start
  // Customizable Area End

export default class FreelancerAllDisputesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    submitContractsId: string = ""
    allDiputesId: string = ""
    allDetailsCallId: string = ""
    // Customizable Area End
    constructor(props:Props){
        super(props) ;
        this.receive=this.receive.bind(this);
    this.subScribedMessages=[getName(MessageEnum.RestAPIResponceMessage)];
        // Customizable Area Start

        this.state={
            age: "",
            open: false,
            showJobs:false,
            date:"",
            activeButton: "offers",
            activeTab: 0,
            selectMilestones: 'Milestone 3',
            authToken:'',
            value: '',
            uploadedFileName: '',
            isOpenModal: false,
            succesMessage: '',
            milestoneupdate:false,
            allDisputesData: [],
            allDetailsDisputesData: []
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
}

    async componentDidMount () {
        super.componentDidMount()

        // Customizable Area Start
        this.setState({
            authToken: await storage.get('authToken')
        })
        this.getAllDisputesApiCall()
        this.getAllDetailsApiCall()
        // Customizable Area End
    };



    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
          
          // return;
        }
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const webApiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
      
          let webResponseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
         this.handleSubmitProjectTrackingRes(webResponseJson,webApiRequestCallId)
         this.handleGetAllDisputesRes(webResponseJson,webApiRequestCallId)
         this.handleAllDetailsDataRes(webResponseJson,webApiRequestCallId)
        }
        // Customizable Area End
      }
    // Customizable Area End
    
    // Customizable Area Start

        handleSubmitProjectTrackingRes = (webResponseJson: { data: any; error: 'string',message: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.submitContractsId) {
                this.setState({
                    isOpenModal: true,
                    succesMessage: webResponseJson?.message
                })
              if(webResponseJson?.data){
                this.setState({
                    isOpenModal: true,
                    succesMessage: 'Milestone has been successfully updated',
                    milestoneupdate: true
                })
              }
            }
          }
      }

      handleGetAllDisputesRes = (webResponseJson: { data: any; error: 'string',message: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.allDiputesId) {
                this.setState({
                    allDisputesData: webResponseJson
                })
            }
          }
      }

      handleAllDetailsDataRes = (webResponseJson: { data: any; error: 'string',message: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.allDetailsCallId) {
                this.setState({
                    allDetailsDisputesData: webResponseJson?.data
                })
            }
          }
      }
      
    handleChangeS = (event: any ) => {
        this.setState({
            age: event.target.value as string
        })
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleChanges = (event: any ) => {
        this.setState({
            date: event.target.value as string
        })
    };
    handleClickAway = () => {
        this.setState({ showJobs: true});
      }

      handleCloseModal = () =>{
        this.setState({isOpenModal : false})
      }


      getAllDetailsApiCall = async () => {
        const paramId = this.props.navigation.getParam("id")
    
        const header = {
          "Content-Type": webConfigJSON.validationApiContentType,
          token: this.state.authToken,
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
    
        this.allDetailsCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/contracts/contract_details?id=${paramId}`
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    getAllDisputesApiCall = async () => {
      const header = {
          token: this.state.authToken,
          "Content-Type": webConfigJSON.validationApiContentType,
      }
  
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      )

      this.allDiputesId = requestMessage.messageId
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_refundmanagement/refunds/get_refund_list_freelancer_side`
      )

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true
  };


      handleSubmitMilestone = (id: any, milestoneData: any) => {
     
        const header = {
          "token": this.state.authToken
        };
        const submitContracts = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          
        const formData = new FormData();
        formData.append('milestone[upload_works][]', milestoneData.uploadedFileName);
        formData.append('milestone[description]', milestoneData.milestone_description);
        formData.append('milestone[date]', milestoneData.milestone_date);
        formData.append('milestone[status]', milestoneData.milestone_status);
        
      
        this.submitContractsId = submitContracts.messageId

        submitContracts.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          )

        submitContracts.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_contract/milestones/${id}/freelancer_milestone_submission`
        )
        submitContracts.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData 
        );
        submitContracts.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'PATCH'
        )
        runEngine.sendMessage(submitContracts.id, submitContracts)
      };

      handleTabChange = (event: any, newValue: number) => {
        this.setState({ activeTab: newValue });
      };


    // Customizable Area End
}

     // Customizable Area Start
    // Customizable Area End  
