import React from "react";
// Customizable Area Start
import {  
    Typography,
    Box,  
    MenuItem,   
    styled,
    Link,
    Divider,
    Tab,
    TextField,
    IconButton
} from "@material-ui/core";
import { shap } from "./assets";
import { createTheme } from "@material-ui/core/styles";
import FooterWeb from "./Components/Footer.web";
import ClientNavBar from "./Components/ClientNavBar.web";
import Tabs from "@material-ui/core/Tabs";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        },
    },
    typography: {
        fontFamily: "Inter",
    },
});
const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }

})
import ArchivedContractController, {
    Props,
    webConfigJSON,
} from "./ArchivedContractController.web";

// Customizable Area End

export default class ArchivedContract extends ArchivedContractController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
     renderContract = () => {    
        return (
            <Box>
                {/* <HeaderTxt>{webConfigJSON.allcontract}</HeaderTxt> */}
              
                <TitleTxt>
                Lorum Ipsum Rather skip the guesswork? Shop projects already planned for you by the talent who know them best. solutions to the same.this will help users solve theirLorum Ipsum Rather skip the guesswork? 
                Shop projects already planned for you by the talent who know them best
                </TitleTxt>
                </Box>
        );
    };
    renderdate = () => {
        const { selectedProfessional, startDate, endDate } = this.state
        return (
            <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column">
                    <Typography variant="caption" style={{marginBottom:"4px"}}>
                        Period
                    </Typography>
                    <DateBoxDesign >
                        <Typography variant="body1">
                            {startDate} - {endDate}
                        </Typography>
                        <IconButton >
                            <CalendarTodayIcon />
                        </IconButton>
                    </DateBoxDesign>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
                    <Typography variant="caption" style={{ marginBottom: "4px" }}>
                        IP Professional
                    </Typography>
                    <TextField
                        select
                        value={selectedProfessional}
                        // onChange={this.handleDropdownChange}
                        variant="outlined"
                        size="small"
                        style={{ width: "340px", minHeight:"2rem",borderRadius: "20px" }}
                    >
                        <MenuItem value="All Professionals">All Professionals</MenuItem>
                        <MenuItem value="Professional 1">Professional 1</MenuItem>
                        <MenuItem value="Professional 2">Professional 2</MenuItem>
                    </TextField>
                </Box>
            </Box>

        )
    }
    renderProject = () => {
        return (
            <ProjectOuterMainBox style={webStyle.boxStyle}>
                {this.state.demoData.map((item: any, index: number) => (
                    <ProjectInnerMainBox key={index.toString()}                       >
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <PriceBox>
                                <PriceTxt>${item.price}</PriceTxt>
                                <PriceTxt>per/Hour</PriceTxt>
                            </PriceBox>
                            <DateBox>
                                <img src={shap} style={webStyle.calenderStyle} />
                                <DateTxt>{item.date}</DateTxt>
                            </DateBox>
                        </Box>
                        <Box style={webStyle.fixedfiled}>
                            <ProjectNameTxt>{item.projectTitle}</ProjectNameTxt>
                            <ProjectOwnerNameTxt>{item.projectOwner}</ProjectOwnerNameTxt>
                        </Box>
                        <ButtonBox onClick={this.goToActiveContract} data-test-id={"goToActiveFiled" + index}>
                            <ProjectTxt>{webConfigJSON.goToProject}</ProjectTxt>
                            <ArrowForwardIcon style={webStyle.arrowIcone} />
                        </ButtonBox>

                    </ProjectInnerMainBox>
                ))}
            </ProjectOuterMainBox>
        );
    }

    render() {
        //Customizable Area Start
        return (
            <>               
                <BoxMain>                   
                    {this.renderContract()}
                    {this.renderdate()}
                    {this.renderProject()}
                </BoxMain>              
            </>
        );
        //Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    arrowIcone: {
        color: 'white'
    },

    fixedfiled: {
        margin: "22px 0px"
    },

    calenderStyle: {
        width: "14px",
        height: "15px",
        marginRight: "5px"
    },
    boxStyle: {
        padding: '32px',
        marginBottom: '32px',
        border: '1px solid #CDCDCD',
        borderRadius: '16px',
    }  
};
const webStyles = {
    mainConatiner: {
        padding: "30px",
    },
    jobBtnBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap" as "wrap",
    },
    dashboardText: {
        fontFamily: "Rubik",
        fontSize: '32px',
        fontWeight: 400,
    },
    jobText: {
        fontSize: "20px",
        fontFamily: "Inter"
    },
    myJobText: {
        color: "#206FC4",
        fontFamily: "Inter"
    },
    postBtn: {
        borderRadius: "7px",
        padding: "1% 2%",
        fontFamily: "Inter"
    },
    borderBox: {
        background: "#F5F7F9",
        padding: "15px",
        borderRadius: "5px",
        marginTop: "3%",
        overflow: "auto",
    },


};
const BoxMain = styled(Box)({
    margin: "0px"
});
const FontActiveText = styled(Typography)({
    color: "#206FC4",
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left"
});

const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const CurUpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});

const HeaderTxt = styled(Typography)({
    color: "3F3F3F",
    fontWeight: "bold",
    fontSize: "36px",
    fontFamily: "Rubik, sans-serif",
});

const TitleTxt = styled(Typography)({
    margin: "32px 0px",
    display: "flex",
    fontSize: "14px",
    fontFamily: "Rubik, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#595959"
});
const ProjectOuterMainBox = styled(Box)({
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "30px",
    padding: "20px",
});
const LinkText = styled(Link)({
    color: "#353535",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
    letterSpacing: "0px",
    textAlign: "left",
    textDecoration: 'underLine'
})
const ProjectInnerMainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "356px",
    minWidth: "300px",
    maxHeight: "269px",
    minHeight: "200px",
    position: "relative",
    background: "#F5F8FC",
    borderRadius: "10px",
    padding: "20px",
    justifyContent: "space- between",
    flex: "1 1 calc(33.333% - 16px)",
    boxSizing: "border-box",
    gap: "8px"
});
const PriceBox = styled(Box)({
    width: "70px",
    background: "#E2ECF6",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "5px"
});
const PriceTxt = styled(Typography)({
    fontSize: "12px",
    fontWeight: "normal",
    color: "#364BA0"
});
const DateBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
});
const DateBoxDesign=styled(Box)({
    display:"flex",
    justifyContent:"space-between", 
    alignItems:"center" ,
    width: "340px",
    maxHeight:"40px",
    border:'1px solid #CDCDCD',
    borderRadius: "5px" ,
    padding:"0px 20px"
})
const DateTxt = styled(Typography)({
    color: "#6F6F6F",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500
});
const ProjectTxt = styled(Typography)({
    color: "#FFFFFF",
    marginRight: "10px",
    fontSize: "20px",
    fontFamily: "Rubik, sans-serif",
    fontWeight: 500
});

const ProjectNameTxt = styled(Typography)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Rubik, sans-serif",
    marginBottom: "8px",
    width: "70%",
    color: "#414141"
});

const ProjectOwnerNameTxt = styled(Typography)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontFamily: "Rubik, sans-serif",
    fontWeight: 400
});
const ButtonBox = styled(Box)({
    background: "#364BA0",
    height: "64px",
    maxWidth: "308",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px",
    cursor: "pointer"
});


// Customizable Area End