import React from 'react';

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  Checkbox,
  FormControl,
  Button,
  styled,
  DialogContent,
  Dialog,
  IconButton
} from '@material-ui/core';
import SendProposalsController, { Props } from './SendProposalsController.web';
import { locationIcon } from './assets';
import Rating from '@material-ui/lab/Rating/Rating';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import GetAppIcon from '@material-ui/icons/GetApp';
import { blue } from '@material-ui/core/colors';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { pricetag, prediction } from './Components/assets';

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class SendProposals extends SendProposalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const GreenCheckbox = withStyles({
      root: {
        color: '#535353',
        '&$checked': {
          color: blue[600]
        }
      },
      checked: {}
    })(Checkbox);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <CustomBox>
          <Box>
          <Typography
                style={{
                  ...webStyle.text,
                  marginTop: '72px',
                  fontSize: '36px',
                  fontWeight: 700,
                  fontFamily: 'Rubik',
                  color:'#4A4A4A',
                  // marginLeft: '100px',
                  marginBottom: '33px',
                }}
              >
              Job Details
              </Typography>
          </Box>
          <Grid container spacing={2} style={{ display: 'flex' }}>
            <Grid item className={classes.gridItem}>
              <Box className={classes.mainbox}>
                <Typography style={{paddingLeft: '24px'}} className={classes.text}>
                  {this.state.jobDetails.name}
                </Typography>
                <Box className={classes.subBox}>
                  <Box className={classes.locationBox}>
                    <img src={locationIcon} className={classes.location} />
                    <Typography style={{ fontWeight: 500, marginLeft: '5px',
                            textTransform: 'capitalize',color:'black',fontSize: '14px',fontFamily:'Rubik' }}>
                    {this.state.jobDetails.location}
                    </Typography>
                     <Rating
                name="simple-controlled"
                value={3}
                disabled
                style={{
                  padding: '8px 0px',
                  width: '90px',
                  marginLeft: '7px',
                  marginRight: '15px'
                }}
              />
              <Typography style={{ color: '#0A0A0A',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '14px',
                fontWeight: 300, marginLeft: '30px' }}>
                2.91/5
              </Typography>
                  </Box>
                  <Box className={classes.locationBox}>
                    <Typography style={{ marginLeft: '6px', fontWeight: 400,
                      fontFamily: 'Rubik, Regular',
                      fontSize: '14px' }}>
                    {this.state.jobDetails.created_at}
                    </Typography>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                <Box style={{paddingLeft: '24px'}}>
                  <Typography style={{color: '000000'}} className={classes.text}>
                    Project Descriptions:
                  </Typography>
                  <Typography className={classes.text2}>
                   {this.state.jobDetails.description}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Box style={{display: 'flex',paddingLeft: '24px'}}>
                  <Box >
                    <Box display={'flex'} style={{gap:'10px'}}>
                  <img src={pricetag} alt="img" width={25} height={25}/> 
                  <Typography style={{color: '000000',marginTop:'0px'}} className={classes.text}>
                   ${this.state.jobDetails.per_day_charge}
                  </Typography>
                  </Box>
                  <Typography className={classes.text2} style={{marginTop: '0px',marginLeft: '35px'}}>
                   Project Budget
                  </Typography>
                  </Box>
                  <Box>
                    {this.state.jobDetails.experience_level &&
                    <Box display={'flex'} style={{gap:'10px'}}>
                 <img src={prediction} alt="img" width={25} height={25}/> 
                  <Typography style={{color: '000000',marginTop:'0px'}} className={classes.text}>
                  {this.state.jobDetails.experience_level}
                  </Typography>
                  </Box>
                    }
                  {this.state.jobDetails.intermediate ? 
                    <Typography style={{width: '50%',marginTop: '0px',marginLeft: '35px'}} className={classes.text2}>
                    {this.state.jobDetails.intermediate}
                    </Typography>
                    :
                    <Typography style={{display: 'flex',marginTop: '0px',marginLeft: '35px'}} className={classes.text2}>
                    {'-'}
                    </Typography>
                  }
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                <Typography className={classes.text} style={{paddingLeft: '24px'}}>
                  Skills and expertise:
                </Typography>
                <Box style={{ display: 'flex',paddingLeft: '24px' }}>
                  {this.state.jobDetails.skills?.map((skill: any, index: number) => {
                    return (
                      <Typography className={classes.php} key={index}>
                        {skill}
                      </Typography>
                    );
                  })}
                </Box>
                <Divider className={classes.divider} />
                <Box style={{paddingLeft:'24px'}}>
                  <Grid spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>1</Avatar>
                      <Box>
                        <Typography className={classes.avatarHeading}>
                        Hours
                        </Typography>
                        <Typography
                          className={classes.text3}
                        >
                          Less than {this.state.jobDetails.weekly_hours ?
                          this.state.jobDetails.weekly_hours: '30' } hrs/week
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>2</Avatar>
                      <Box>
                        <Typography className={classes.avatarHeading}>
                         {this.state.jobDetails.project_length}
                        </Typography>
                        <Typography
                          className={classes.text3}
                        >
                          Project length
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>3</Avatar>
                      <Box>
                        <Typography className={classes.avatarHeading}>
                          Intermediate
                        </Typography>
                        <Typography
                          className={classes.text3}
                        >
                          {this.state.jobDetails.intermediate}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>4</Avatar>
                      <Box>
                        <Typography className={classes.avatarHeading}>
                          Location
                        </Typography>
                        <Typography
                          className={classes.text3}
                          style={{textTransform: 'capitalize'}}
                        >
                            {this.state.jobDetails?.states?.length > 0 &&
                        this.state.jobDetails.states.map((itm: any, index: number) => (
                          <span key={index}>
                            {index > 0 && ', '}
                            {itm?.name}
                          </span>
                        ))}
                           {", "} {this.state.jobDetails?.location || this.state.jobDetails?.country?.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.subgrid}
                    >
                      <Avatar className={classes.avtar}>5</Avatar>
                      <Box>
                        <Typography className={classes.avatarHeading}>
                        Patent
                        </Typography>
                        {this.state.jobDetails.patents?.map((patent: any, index: number) => {
                    return (
                      <>
                      <Typography
                      className={classes.text3}
                    >
                      {patent?.categories?.name}
                    </Typography>
                    {patent?.categories?.sub_categories?.map((subcategory: any)=>{
                      return(
                        <>
                        <Typography
                        className={classes.text3}
                      >
                        {subcategory?.name}
                      </Typography>
                      {subcategory?.sub_category_menus?.map((submenu: any)=>{
                      return(
                        <>
                        <Typography
                        className={classes.text3}
                      >
                        {submenu?.title}
                      </Typography>
                      {submenu?.sub_category_sub_menus?.map((subCategorymenu: any)=>{
                      return(
                        <>
                        <Typography
                        className={classes.text3}
                      >
                        {subCategorymenu?.title}
                      </Typography> 
                      </>
                      )
                    })}
                      </>
                      )
                    })}
                      </>
                      )
                    })}
                    </>
                    );
                  })}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider className={classes.divider} />
                <Typography style={{fontSize:'16px',paddingLeft:'24px'}} className={classes.text}>
                  Project time:{' '}
                  <span style={{ fontSize:'16px',fontWeight: 400, color: '#555555' }}>
                    Ongoing Project
                  </span>
                </Typography>
                <Divider className={classes.divider} />
                <>
    <Typography style={{paddingLeft:'24px'}} className={classes.text}>
      Activity on this job:
    </Typography>
    <Box style={{paddingLeft:'24px'}}>
        <Box style={{display:'flex',gap:'5px'}}>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '16px',color:'rgb(85, 85, 85' }}>
         Proposals{" "}:
        </Typography>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '16px',color:'rgb(85, 85, 85' }}>
         {this.state.jobDetails.proposals}
        </Typography>
        </Box>
        <Box style={{display:'flex',gap:'5px'}}>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px', color:'rgb(85, 85, 85' }}>
         Invite sent{' '}:
        </Typography>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px' , color:'rgb(85, 85, 85'}}>
        {this.state.jobDetails.invites_sent}
        </Typography>
        </Box>
    </Box>
    <Divider className={classes.divider} />
    <Typography style={{paddingLeft:'24px'}} className={classes.text}>
      About the client:
    </Typography>
    <Box style={{display:'flex',gap:'5px',paddingLeft: '24px'}}>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px', color:'rgb(85, 85, 85' }}>
        Job posted{' '}:
        </Typography>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px' , color:'rgb(85, 85, 85'}}>
        {this.state.jobDetails.posted_job}
        </Typography>
        </Box>
        <Box style={{display:'flex',gap:'5px',paddingLeft: '24px'}}>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px', color:'rgb(85, 85, 85' }}>
        Invite sent{' '}:
        </Typography>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px' , color:'rgb(85, 85, 85'}}>
        {this.state.jobDetails.invites_sent}
        </Typography>
        </Box>
        <Box style={{gap:'5px',paddingLeft: '24px'}}>
        <Typography style={{ fontFamily: "'Rubik", marginTop: '10px',marginBottom: '32px', color:'rgb(85, 85, 85',textTransform: 'capitalize' }}>
        {this.state.jobDetails?.location || this.state.jobDetails?.country?.name}
        </Typography>
        </Box>
        {this.state.jobDetails?.files && (
  <>
    <Divider className={classes.divider} />
    <Typography style={{ paddingLeft: '24px' }} className={classes.text}>
      Attachment:
    </Typography>
    <Box style={{ display: 'flex', gap: '5px',
    flexWrap: 'wrap',
       paddingBottom: '32px', marginTop: '20px', paddingLeft: '24px' }}>
      {this.state.jobDetails.files.map((file: any, index: number) => (
        <Box key={index} style={{ display: 'flex', gap: '5px',
          marginBottom: '10px'
         }}>
          {file.file.includes('image') ? (
            <Box>
              <img
                src={file.file}
                alt={`image-${index}`}
                style={{ width: '70px', height: 'auto' }}
              />
            </Box>
          ) : (
            <Box
              style={{
                width: 'max-content',
                padding: '15px',
                height: '100px',
                background: '#efefff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontSize: '12px' }}>{file.name}</Typography>
            </Box>
          )}
          <IconButton
            className={classes.iconbtn}
            onClick={() => this.handleDownload(file.file, file.file_type)}
            data-test-id="handleDownload"
            style={{ alignItems: 'end' }}
          >
            <GetAppIcon style={{ fontSize: '25px' }} />
          </IconButton>
        </Box>
      ))}
    </Box>
  </>
)}


  </>
              </Box>
            </Grid>
            <Grid item className={classes.gridItem1}>
              <Box className={classes.mainbox2}>
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography style={{color: '4A4A4A', fontSize:'24px',margin:'0px'}} className={classes.text}>
                   ${this.state.jobDetails.per_day_charge}
                  </Typography>
                  <Typography className={classes.proposals}>
                    Proposals:{this.state.jobDetails.proposals}
                  </Typography>
                </Box>
                <Typography className={classes.budget}>Project Budget</Typography>
                <Box>
                  <FormControl fullWidth>
                    <Button
                      onClick={this.handleApply}
                      className={classes.applyBtn}
                      variant="contained"
                      color="primary"
                    >
                      SEND PROPOSAL
                    </Button>                   
                  </FormControl>
                  <FormControl fullWidth>
                  <Button
        style={{ cursor: 'pointer' , 
                 border: '1px solid #364BA0', 
                 color:'#364BA0',
                 padding: '8px',
                borderRadius: '10px',
                fontWeight: 700,
                fontSize:'18px',
                fontFamily: 'Rubik',
                marginTop: '15px'
                }}
                data-test-d="favourites-job"
                onClick={() => {
                  const jobId = this.state.jobDetails.id;
                  if (!this.state.favouritesJobIds.includes(jobId)) {
                    this.handleSaveforLaterJobs(jobId); 
                    this.setState({ jobSavePopup: true }); 
                  }
                }}
      >
        <FavoriteBorderIcon
          style={{  margin: '0px 5px'}}
          data-testid={`favorite`}
        /> 
         {this.state.favouritesJobIds.includes(
          this.state.jobDetails.id
        )
          ? 'SAVED'
          : 'SAVE FOR LATER'}
                  </Button>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          </Grid></CustomBox>
             <FooterWeb />
        {this.state.jobSavePopup && <Dialog  PaperProps={{style: { borderRadius: '24px' },}}
        open={this.state.jobSavePopup} 
        onClose={this.handleSavePopup}>
      <div style={{ width: 400, backgroundColor: 'white', padding: 30}}>
        <DialogContent><Typography 
        style={{...webStyle.modalText, 
        marginBottom:'10px' }}>
          Congratulations!!
          </Typography>
          <Typography 
          style={webStyle.modalText}>
            You have successfully saved the job for later.
            </Typography></DialogContent>
        <Button 
        onClick={this.handleSavePopup} 
        style={{ fontSize: '16px',
          width: '50%',
          margin: 'auto',
          display: 'flex',
          color: 'white',
          marginTop: '40px',
          fontWeight: 700,
          borderRadius: '12px',
          marginBottom: '30px',
          background:'#364BA0',
          }}>OK</Button></div>
    </Dialog>
    }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: '100%',
    backgroundColor: 'lightblue',
    padding: '10px',
    border: '1px solid #ccc',
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#206FC4'
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 6px)'
    }
  },
  modalText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#515354',
    fontSize: '18px',
  },
  mainbox: {
    border: '1px solid #E1E1E1',
    borderRadius: '15px',
  },
  text: {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Rubik, Regular',
    marginTop: '24px',
    color: '#303030'
  },
  location: {
    height: '20px'
  },
  locationBox: {
    display: 'flex',
    fontFamily: 'Inter',
    alignItems: 'center',
    color: '#555555'
  },
  divider: {
    backgroundColor: '#E1E1E1',
    marginTop: '24px',
    marginBottom: '24px',
    height: '2px'
  },
  subBox: {
    paddingLeft: '24px'
  },
  text2: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Regular,Rubik',
    marginTop: '10px',
    color: '#3F3F3F',
    marginRight: '25px'
  },
  php: {
    background: ' #E4F5FF',
    borderRadius: '30px',
    width: 'fit-content',
    padding: '5px 20px 5px 20px',
    color: '#364BA0',
    fontWeight: 600,
    fontFamily: 'Rubik',
    marginTop: '22px',
    marginRight: '5px'
  },
  avtar: {
    backgroundColor: ' #364BA0',
    fontWeight: 200,
    fontFamily: 'Rubik',
    color: 'white',
    padding: '1px',
    fontSize: '24px'
  },
  subgrid: {
    display: 'flex',
    gap: '12px',
    marginBottom: '26px'
  },
  avatarHeading: {
    fontSize: '16px',
    fontFamily: 'Rubik',
    color: '#000000',
    fontWeight: 600,
  },
  iconbtn:{
    padding: 0,
    '& .MuiIconButton-root':{
      padding: 0,
      fontSize: '20px'
    }
  },
  text3: {
    fontSize: '14px',
    fontFamily: 'Rubik',
    color: '#595959',
    fontWeight: 400,
    marginTop: '4px',
  },
  mainbox2: {
    border: '1px solid #E1E1E1',
    borderRadius: '15px',
    marginLeft: '25px',
    padding: '24px'
  },
  text4: {
    fontSize: '22px',
    fontFamily: 'Roboto',
    padding: '20px 20px 0px 20px',
    color: ' #525252',
  },
  proposals: {
    fontSize: '16px',
    fontFamily: 'Rubik,Regular',
    color: ' #4A4A4A',
  },
  gridItem: {
    width: '75%',
    '@media (max-width:1600px)': {
      width: '70%',
    },
    '@media (max-width:1300px)': {
      width: '65%',
    },
    '@media (max-width:1200px)': {
      width: '60%',
    },
    '@media (max-width:1100px)': {
      width: '55%',
    },
    '@media (max-width:960px)': {
      width: '50%',
    },
    '@media (max-width:850px)': {
      width: '100%', 
    },
  },  
  gridItem1: {
    width: '25%',
    '@media (max-width:1600px)': {
      width: '30%',
    },
    '@media (max-width:1300px)': {
      width: '35%',
    },
    '@media (max-width:1200px)': {
      width: '40%',
    },
    '@media (max-width:1100px)': {
      width: '45%',
    },
    '@media (max-width:960px)': {
      width: '50%', 
    },
    '@media (max-width:850px)': {
      width: '100%', 
    },
  },
  budget: {
    fontSize: '16px',
    fontFamily: 'Rubik,Regular',
    color: ' #4A4A4A',
  },
  applyBtn: {
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: '#364BA0',
    marginTop: '50px',
    fontWeight: 700,
    fontSize:'18px',
    fontFamily: 'Rubik',
    cursor:'pointer',
    '&:hover': {
      backgroundColor: '#2676D8'
    }
  },
  favouriteBtn: {
    padding: '12px',
    borderRadius: '8px',
    marginTop: '10px',
    border: '1px solid #2676D8',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #2676D8'
    }
  }
};

const CustomBox = styled(Box)({
  marginTop: '33px', paddingLeft:'88px',paddingRight: '88px',
  '@media (max-width:450px)': {
    paddingLeft:'10px',paddingRight: '10px',
  },
});

export default withStyles(webStyle)(SendProposals);
// Customizable Area End
